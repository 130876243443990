import { useEffect, useState } from 'react';
import apiInstance from './api';
import { useNavigate } from "react-router-dom";

export const jwtKeyAccess = 'access_token';
export const jwtKeyRefresh = 'refresh_token';

export interface token_t {
    accessToken: string;
    refreshToken: string;
}

apiInstance.interceptors.request.use(
    (config) => {
        // Add token authorization to the request
        const authTokens = getStrAuthTokens();
        config.headers.setAuthorization(authTokens);

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const useAuthInterceptor = () => {
    const navigate = useNavigate(); // Ajoute cette ligne pour obtenir la fonction de navigation
    const [isAuthSet, setIsSet] = useState(false);

    useEffect(() => {
        const interceptor = apiInstance.interceptors.response.use(
            (res) => res,
            async (error) => {
                const originalConfig = error.config;

                // Not refresh token required
                if (!originalConfig ||
                    originalConfig.url === "/auth/login" || // Login request
                    error.response.status !== 401 || // Other error than invalide access token
                    originalConfig.url === "/auth/refreshToken") // Refresh token resquest
                {
                    return Promise.reject(error);
                }

                // Access Token was expired
                try {
                    // Request to update access token
                    const rs = await apiInstance.post<token_t>("/auth/refreshToken", {
                        refreshToken: getRefreshToken,
                    });
                    setLocalToken(rs.data);

                    // retry the request
                    return apiInstance(originalConfig);
                } catch (_error) {
                    removeToken();
                    navigate('/login');
                }
            }
        );

        setIsSet(true);

        // clean interceptor to not have one more interceptor at each reload
        return () => {
            apiInstance.interceptors.response.eject(interceptor);
        };
    }, [navigate]);

    return isAuthSet;
}

const getRefreshToken = () => {
    return localStorage.getItem('refresh_token');
}

export const setLocalToken = (userToken: token_t | null) => {
    const accToken = userToken?.accessToken ?? ""
    const refreshToken = userToken?.refreshToken ?? ""

    localStorage.setItem('access_token', accToken);
    localStorage.setItem('refresh_token', refreshToken);
}

export const getAuthHeaderParam = () => {
    return {
        name: 'authorization',
        value: `${localStorage.getItem('access_token')}`
    }
}

export const getStrAuthTokens = () => {
    return JSON.stringify({
        accessToken: localStorage.getItem('access_token'),
        refreshToken: localStorage.getItem('refresh_token'),
    });
}

export const removeToken = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
}