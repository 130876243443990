import { useState } from "react";
import { token_t } from "../utils/auth";
import { useNavigate } from "react-router-dom";
import apiInstance from "../utils/api";

interface Props {
  logUser: (token: token_t) => void;
}

const Login = ({ logUser }: Props) => {
  const navigate = useNavigate();
  const [resMessage, setResMessage] = useState<string | undefined>(undefined);
  const [displayPassword, setDisplayPassword] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const pseudo = event.currentTarget.pseudo.value;
    const password = event.currentTarget.password.value;

    apiInstance.post<token_t>('/auth/login', {
      pseudo: pseudo,
      password: password
    })
      .then(res => {
        if (res.status === 200) {
          logUser(res.data);
          navigate("/");
        } else if (res.status === 401) {
          setResMessage(res.statusText);
        } else {
          setResMessage("Erreur serveur");
        }
      })
      .catch(err => {
        setResMessage(err.response.data);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="container">

        <div className='row justify-content-center text-center'>
          <div className={`col-md-4 col-10 p-0 mb-4`}>
            <h1>Connexion</h1>
          </div>
        </div>

        {resMessage &&
          <div className='row justify-content-center'>
            <div className={`col-md-4 col-10 p-0`}>
              <div className="alert alert-danger" role="alert">
                {resMessage}
              </div>
            </div>
          </div>
        }

        <div className='row justify-content-center'>
          <div className={`col-md-4 col-10 p-0 mb-4`}>
            <div className="input-group ">

              <span className="input-group-text" id="basic-addon1">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                </svg>
              </span>

              <input
                className="form-control"
                type="text"
                name="pseudo"
                placeholder="Pseudo"
                required
                aria-label="Username"
                aria-describedby="basic-addon1"
              />

            </div>

          </div>
        </div>

        <div className='row justify-content-center'>
          <div className={`col-md-4 col-10 p-0 mb-4`}>
            <div className="d-inline-flex input-group border border-1 rounded">
              <span className="input-group-text border border-0" id="basic-addon1">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lock-fill" viewBox="0 0 16 16">
                  <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                </svg>
              </span>

              <input
                id="passwordField"
                className="form-control border border-0 border-start"
                type={displayPassword ? 'text' : 'password'}
                name="password"
                placeholder="Mot de passe"
                required
              />

              <button onClick={() => setDisplayPassword(!displayPassword)} className="btn position-relative" type="button" id="button-addon2" style={{ border: 'none', bottom: '0.45rem', right: '0.65rem', marginLeft: '0.3rem' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill position-absolute" viewBox="0 0 16 16">
                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                </svg>
              </button>
            </div>

          </div>
        </div>

        <div className='row justify-content-center'>
          <div className={`col-md-4 col-10 p-0 mb-4`}>
            <input className="btn btn-primary w-100" type="submit" value="Se connecter" />
          </div>
        </div>

      </form>
    </div>
  );
};

export default Login;