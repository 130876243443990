import { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom'
import './App.css';
import Player from './Page/Player';
// import Uploader from './Page/Uploader';
import { io } from 'socket.io-client';
import Home from './Page/Home';
import Signup from './Page/Signup';
import Login from './Page/Login';
import { setLocalToken, token_t, useAuthInterceptor } from './utils/auth';
import ConditionalRoot from './Component/ConditionRoot';
import { UserAuth, extractTokenUser, roles } from './utils/user';
import Navbar from './Component/Navbar';
import Serie from './Page/Serie';
import { SeasonPage } from './Page/Season';
import Uploader from './Page/Uploader';
import Test from './Page/Test';

export const socket = io();
socket.on('connect', () => {
  // console.log("Socket client connected");
});

const App = (): JSX.Element => {
  const navigate = useNavigate();
  const [user, setUser] = useState<UserAuth>({ userId: null, role: null });
  const isAuthSet = useAuthInterceptor();

  useEffect(() => {
    const token = getToken();
    logUser(token);

    if (window.location.pathname !== '/login' && !token) {
      navigate('/login/');
    }
  }, [navigate]);

  function logUser(userToken: token_t | null) {
    setUser(extractTokenUser(userToken?.accessToken));
    setLocalToken(userToken);
  }

  function getToken(): token_t | null {
    const access_token = localStorage.getItem('access_token');
    const refresh_token = localStorage.getItem('refresh_token');

    if (!access_token || !refresh_token)
      return null;

    return {
      accessToken: access_token,
      refreshToken: refresh_token
    }
  }

  const isLogged = () => {
    return getToken() !== null;
  }

  if (!isAuthSet) return <></>;

  return (
    <>
      <Navbar
        isLogged={isLogged()}
        setUser={setUser}
        userRole={user.role}
      />

      <Routes>

        <Route element={<ConditionalRoot redirectPath='/login' condition={isLogged()} />}>

          <Route path='/'>
            <Route index element={<Home />} />

          </Route>

          <Route path='/play/:id' element={<Player />} />

          <Route path='/serie' element={<Serie />} />

          <Route path='/serie/:serieTitle' element={<SeasonPage />} />

          <Route element={<ConditionalRoot redirectPath='/' condition={user.role === roles.ADMIN} />}>
            <Route path='/signup' element={<Signup />} />
            <Route path='/uploader' element={<Uploader />} />
            <Route path='/test' element={<Test />} />
          </Route>

        </Route>

        <Route path='/login' element={<Login logUser={logUser} />} />

        <Route path="*" element={<p>Page not found</p>} />
      </Routes>
    </>
  );
}

export default App;