import jwt_decode from "jwt-decode";

export type Role = "ADMIN" | "USER";

export const roles = {
    ADMIN: "ADMIN",
    USER: "USER"
}

export interface UserAuth {
    userId: number | null;
    role: Role | null;
}

interface Playload {
    userInfo: UserAuth;
    exp: number;
    iat: number;
}

/**
 * Extract userID and role from the token
 * @param accessToken 
 */
export const extractTokenUser = (accessToken: string | undefined): UserAuth => {
    try {
        if (!accessToken)
            throw new Error("No token");

        const decodeToken = jwt_decode<Playload>(accessToken);

        return decodeToken.userInfo;

    } catch (e) {
        return {
            userId: null,
            role: null
        }
    }
}