import { Navigate, Outlet } from "react-router-dom";
import { token_t } from "../utils/auth";

interface Props {
    condition: boolean;
    redirectPath: string;
}

const ConditionalRoot = (props: Props) => {
    if (!props.condition)
        return <Navigate to={props.redirectPath} replace />;

    return <Outlet />;
};

export default ConditionalRoot;