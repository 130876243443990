import { Link, useLocation } from 'react-router-dom'
import { Role, UserAuth, roles } from '../utils/user';
import Logout from './logout';
import logo from "../assets/images/logo.svg"
import { SearchBar } from './searchBar';
import { useRef } from 'react';

interface Props {
  userRole: Role | null
  isLogged: boolean
  setUser: (userAuth: UserAuth) => void
}

const Navbar = (props: Props) => {
  const locationPath = useLocation().pathname;
  const navButton = useRef<HTMLButtonElement>(null);
  const linksContainerRef = useRef<HTMLDivElement>(null);

  const collapseNav = () => {
    if (!navButton.current || !linksContainerRef.current)
      return;

    navButton.current.classList.add("collapsed");
    linksContainerRef.current.classList.remove("show");
  }

  const activeClass = (path: string) => {
    return (path === locationPath) ? 'active' : '';
  }

  const navElem = (path: string, label: string) => {
    const className = `nav-link text-light ${activeClass(path)}`;

    return (
      <li className="nav-item">
        <Link
          className={className}
          aria-current={"location"}
          to={path}
          onClick={collapseNav}
        >
          {label}
        </Link>
      </li>
    );
  }

  return (
    <nav className="navbar navbar-dark navbar-expand-lg bg-body-dark text-light ">
      <div className="container-fluid pb-2 mb-4 border-bottom border-secondary">
        <Link className="navbar-brand" to={'/'}>
          <h3 className='text-light'><img className='m-2' src={logo} height={50} alt="CircleCast_logo" />CircleCast</h3>
        </Link>

        <button
          ref={navButton}
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          ref={linksContainerRef}
          className="collapse navbar-collapse"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav nav-underline me-auto mb-2 mb-lg-0">
            {!props.isLogged ?
              navElem('/login', "Connexion")
              :
              <>
                <SearchBar onClick={collapseNav} />
                {navElem('/', "Film")}
                {navElem('/serie', 'Serie')}

                {props.userRole === roles.ADMIN && [
                  navElem('/signup', "Inscription"),
                  navElem('/uploader', "Uploadeur"),
                  navElem('/test', "Test")
                ]}

              </>
            }
          </ul>

          <Logout isLogged={props.isLogged} resetUser={() => { props.setUser({ userId: null, role: null }) }} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;