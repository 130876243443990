import { useEffect, useRef, useState } from "react";
import { getAuthHeaderParam } from "../utils/auth";
// import ReactPlayer from 'react-player'
import ReactPlayer from 'react-player/lazy'

const widthPlayStyle = { maxWidth: "60rem", width: "100%" };
const Test = () => {
    const [videoUrl, setVideoUrl] = useState<string>(`/api/play/22?${getAuthHeaderParam().name}=${getAuthHeaderParam().value}`);

    const videoRef = useRef<HTMLVideoElement | null>(null);
    useEffect(() => {
        if (videoRef.current)
            videoRef.current.defaultMuted = true;
    })

    return (
        <div className="d-flex flex-column align-items-center mx-lg-5 mx-3">
            <div className="ratio ratio-16x9 my-3" style={widthPlayStyle}>
                <video key={videoUrl} className="rounded shadow" id="videoPlayer" controls playsInline muted>
                    <source src={videoUrl} type="video/mp4" />
                </video>
            </div>

            <div className="ratio ratio-16x9 my-3" style={widthPlayStyle}>
                <video
                    id="videoPlayer"
                    key={videoUrl + "1"}
                    ref={videoRef}
                    loop
                    autoPlay
                    muted
                    playsInline
                    controls
                >
                    <source src={videoUrl} type="video/mp4" />
                </video>
            </div>

            <div className="ratio ratio-16x9 my-3" style={widthPlayStyle}>
                <ReactPlayer muted={true} controls url={videoUrl} />
            </div>

            <div className="ratio ratio-16x9 my-3" style={widthPlayStyle}>
                <ReactPlayer url='https://www.youtube.com/watch?v=LXb3EKWsInQ' />
            </div>
        </div >
    );
};

export default Test;