import { Link } from "react-router-dom";
import { VideoType } from "../utils/video";
import './card.css'
import defaultPoster from "../assets/images/default_movie.png"

interface Props {
    id: number,
    idPoster: number,
    srcPoster: string | null,
    title: string,
    type: VideoType,
    clickPath : string
}

const Card = (props: Props) => {

    const playIcon = <svg width="65" height="65" fill="white" className="bi bi-play-circle mask" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
    </svg>

    return (
        <div className="col">
            <div className='card bg-dark border border-0' key={props.id}>
                
                <div className="card-title">
                    <Link to={props.clickPath}>


                    <div className="trailer_link_disp">
                        {playIcon}
                    </div>

                    <img
                        src={props.srcPoster ?? defaultPoster}
                        className="poster-movie img-fluid rounded"
                        data-mdb-ripple-color="light"
                        alt="lala"
                    />
                        
                    </Link>
                </div>

                <div className="card-body pt-0">
                    <Link className='' to={props.clickPath}>
                        <h5 className='movie-title text-light'>{props.title}</h5>
                    </Link>
                </div>
            </div>
        </div>
        
    );
}

export default Card;