import React, { useState, useEffect, ReactNode } from 'react';
import { socket } from '../App';
import InputTypehead from '../Component/inputTypehead';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { AxiosProgressEvent } from 'axios';
import apiInstance from '../utils/api';
import { VideoType } from '../utils/video';
import { Buffer } from 'buffer'

interface ApiMovie {
    adult: boolean
    backdrop_path: string
    belongs_to_collection: null
    budget: number
    genre_ids: number[]
    homepage: string
    id: number
    imdb_id: string
    original_language: string
    original_title: string
    overview: string
    popularity: number
    poster_path: string
    title: string,
}

const Uploader: React.FC = () => {
    const navigate = useNavigate()
    const [downloadProgress, setDownloadProgress] = useState<number>();
    const [conversionProgress, setConversionProgress] = useState<number>();
    const [errorMessage, setErrorMessage] = useState<undefined | string>(undefined);

    const [searchTitle, setSearchTitle] = useState<string>('');
    const [videoType, setVideoType] = useState<VideoType>('movie');
    const [proposalMovies, setProposalMovies] = useState<ApiMovie[]>([]);
    const [selectMovie, setSelectMovie] = useState<ApiMovie>();

    const [posterUrl, setPosterUrl] = useState<string>("");

    const videoTitleField = 'videoTitle';
    const videoDescriptionField = 'videoDescription';
    const posterUrlField = 'posterUrl';
    const videoPathField = 'videoFile';

    const getFormDataSubmit = (target: any): FormData => {
        const data = new FormData();

        const {
            videoTitle,
            videoDescription,
            videoFile,
            season,
            episode
        } = target;

        data.append(videoTitleField, videoTitle.value);
        data.append(posterUrlField, posterUrl.replace('data:image/jpeg;base64,', ''));
        data.append(videoDescriptionField, videoDescription.value);
        data.append(videoPathField, videoFile.files[0]);
        data.append('userId', "-1");

        if (videoType === 'tv') {
            data.append('season', season.value);
            data.append('episode', episode.value);
        }

        return data;
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setErrorMessage(undefined);

        const data = getFormDataSubmit(event.target);

        const onUploadProgress = (event: AxiosProgressEvent) => {
            if (!event.total)
                return;

            const progress = Math.round((event.loaded / event.total) * 100);
            setDownloadProgress(progress);
        }

        const path = (videoType === 'tv') ? "/upload/tv" : "/upload";

        apiInstance.post(path, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: onUploadProgress
        })
            .then(res => {
                console.log('res', res)
                if (res.status !== 201) {
                    setErrorMessage(res.statusText)
                }
            })
            .catch(err => {
                console.log('err', err)
                setErrorMessage(err.response.data);
            })
    };

    useEffect(() => {
        socket.on('conversionProgress', (conversionProgress) => {
            setConversionProgress(conversionProgress);
        });

        return () => {
            socket.off('conversionProgress');
        };
    }, []);

    const searchApiMovie = (value: string) => {
        setSearchTitle(value);

        if (value.length < 3) {
            return;
        }

        apiInstance.get(`/tmdb/searchVideo?videoType=${videoType}&searchInput=${value}`)
            .then(res => res.data)
            .then(movies => {
                setProposalMovies(movies);
            });
    }

    const handleSelectMovie = (movie: ApiMovie) => {
        setSearchTitle(movie.title);
        setSelectMovie(movie);
        setProposalMovies([]);

        getPosterCompletUrl(movie.poster_path)
            .then(imgUrl => setPosterUrl(imgUrl))
            .catch(err => {
                // console.log('err', err)
            })
    }

    const getPosterCompletUrl = async (posterUrl: string) => {
        let src = ""
        try {
            const res = await apiInstance.get(`/tmdb/poster${posterUrl}`, { responseType: 'arraybuffer' });
            const buffer = Buffer.from(res.data, 'binary').toString('base64');
            src = `data:image/jpeg;base64,${buffer}`;
        } catch (error) {
            // console.log('error getPosterCompletUrl', error)
        }

        return src;
    }

    const ErrorMessage = () => {
        if (errorMessage)
            return (
                <FormRow addedClass=''>

                    <div className="alert alert-danger" role="alert">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-exclamation-triangle-fill me-2" viewBox="0 0 16 16">
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                        {errorMessage}
                    </div>
                </FormRow>

            )
    }

    const uploadEnd = (): boolean => {
        return Boolean(downloadProgress &&
            downloadProgress >= 100 &&
            conversionProgress &&
            conversionProgress >= 100)
    }

    const resetForm = () => {
        setSearchTitle("");
        setSelectMovie(undefined);
        setProposalMovies([]);
        setPosterUrl("");
    }

    return (

        <form onSubmit={handleSubmit} className='container' encType='multipart/form-data'>

            {ErrorMessage()}

            <FormRow addedClass=''>
                <VideoTypeInput
                    videoType={videoType}
                    setVideoType={setVideoType}
                    resetForm={resetForm}
                />
            </FormRow>

            <FormRow addedClass=''>

                <InputTypehead
                    title='Titre'
                    name={videoTitleField}
                    value={searchTitle}
                    options={proposalMovies.map(mv => { return { label: mv.title, value: mv } })}
                    onInputChange={searchApiMovie}
                    onClick={handleSelectMovie}
                    placeholder="Sélectionner un titre pour compléter la description et l'affiche du film"
                />

            </FormRow>

            {videoType === 'tv' && selectMovie ?
                <FormRow>
                    <SerieInput serieId={selectMovie?.id} />
                </FormRow>
                :
                ""
            }

            {selectMovie?.poster_path &&
                <FormRow addedClass='text-center'>
                    <img
                        className='rounded w-50'
                        src={posterUrl}
                    />
                </FormRow>
            }

            <FormRow addedClass='floating-label'>
                <textarea
                    key={videoDescriptionField + '_key'}
                    className='form-control text-dark-emphasis'
                    name={videoDescriptionField}
                    value={selectMovie ? selectMovie.overview : ''}
                    id='videoDescription'
                    cols={60}
                    rows={8}
                    placeholder='Description'
                    disabled
                    style={{ resize: 'none' }}
                />
                <label htmlFor='videoDescription' style={{ display: 'none' }}>Description</label>
            </FormRow>

            <FormRow>
                <label htmlFor='videoFile' className="form-label">Fichier source</label>
                <div id={videoTitleField} className="mb-1 form-text">
                    Les fichiers MP4 n'ont pas besoin d'être convertit. Le téléchargement est plus rapide.
                </div>
                <input
                    className="form-control"
                    required
                    key={'videoFile'}
                    id='videoFile'
                    name={videoPathField}
                    type='file'
                />
            </FormRow>

            <FormRow addedClass='text-center'>
                {downloadProgress &&
                    <ProgressBar title='Téléchargement' value={downloadProgress} error={errorMessage ? true : false} />
                }
                {conversionProgress &&
                    <ProgressBar title='Convertion' value={conversionProgress} error={errorMessage ? true : false} />
                }
            </FormRow>

            <FormRow addedClass='text-center'>
                {uploadEnd() ?
                    <div className='d-inline-flex align-items-center gap-4'>
                        <button
                            onClick={() => navigate(0)}
                            className='d-inline-flex align-items-center gap-2 btn btn-primary'
                            type='button'
                        >
                            <span>Un autre</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
                                <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
                            </svg>
                        </button>
                        <Link
                            to={'/'}
                            className='d-inline-flex align-items-center gap-2 btn btn-success'
                            type='button'
                        >
                            <span>Terminé</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                        </Link>
                    </div>
                    :
                    <input className='btn btn-primary' type="submit" value="Upload" />
                }
            </FormRow>

        </form>
    )
}

const SerieInput = (props: { serieId: number | undefined }) => {
    const [episodePearSeason, setEpisodePearSeason] = useState<number[]>([1]);
    const [nbSeason, setNbSeason] = useState<number>();
    const [selectSeason, setSelectSeason] = useState<number>(1);

    useEffect(() => {
        if (!props.serieId)
            return;

        apiInstance.get(`/tmdb/seasonEpisodeNumber/${props.serieId}`)
            .then(res => res.data)
            .then(data => {
                setNbSeason(data.seasonNumber);
                setEpisodePearSeason(data.episodePearSeason);
            })

    }, [props.serieId])

    return (
        <div className="input-group">

            <select name='season' className="form-select" aria-label="Default select example">
                {Array.from(Array(nbSeason), (_, i) => i + 1).map((seasonNum, i) =>
                    <option
                        key={`season_count_${props.serieId}_${seasonNum}`}
                        value={seasonNum}
                        onClick={() => setSelectSeason(i)}
                    >
                        {`Saison ${seasonNum}`}
                    </option>
                )}
            </select>

            <select name='episode' className="form-select" aria-label="Default select example">
                {Array.from(Array(episodePearSeason[selectSeason]), (_, i) => i + 1).map((episodCount) =>
                    <option
                        key={`episode_count_${props.serieId}_${episodCount}`}
                        value={episodCount}
                    >
                        {`Episode ${episodCount}`}
                    </option>
                )}
            </select>

        </div>
    )
}

const VideoTypeInput = (props: { videoType: VideoType, setVideoType: (value: VideoType) => void, resetForm: () => void }) => {

    const handleChange = (e: any) => {
        const value = e.target.value
        props.resetForm();
        props.setVideoType(value);
    }

    return (
        <div className="btn-group w-100" role="group" aria-label="Basic radio toggle button group">
            <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio1"
                autoComplete="off"
                checked={props.videoType === 'movie'}
                value={'movie'}
                onChange={handleChange}
            />
            <label className="btn btn-outline-primary" htmlFor="btnradio1">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-film" viewBox="0 0 16 16">
                    <path d="M0 1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1zm4 0v6h8V1H4zm8 8H4v6h8V9zM1 1v2h2V1H1zm2 3H1v2h2V4zM1 7v2h2V7H1zm2 3H1v2h2v-2zm-2 3v2h2v-2H1zM15 1h-2v2h2V1zm-2 3v2h2V4h-2zm2 3h-2v2h2V7zm-2 3v2h2v-2h-2zm2 3h-2v2h2v-2z" />
                </svg>
                <span className='ms-2'>Film</span>
            </label>

            <input
                type="radio"
                className="btn-check btn-primary"
                name="btnradio"
                id="btnradio2"
                autoComplete="off"
                checked={props.videoType === 'tv'}
                value={'tv'}
                onChange={handleChange}
            />
            <label className="btn btn-outline-primary" htmlFor="btnradio2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-tv" viewBox="0 0 16 16">
                    <path d="M2.5 13.5A.5.5 0 0 1 3 13h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zM13.991 3l.024.001a1.46 1.46 0 0 1 .538.143.757.757 0 0 1 .302.254c.067.1.145.277.145.602v5.991l-.001.024a1.464 1.464 0 0 1-.143.538.758.758 0 0 1-.254.302c-.1.067-.277.145-.602.145H2.009l-.024-.001a1.464 1.464 0 0 1-.538-.143.758.758 0 0 1-.302-.254C1.078 10.502 1 10.325 1 10V4.009l.001-.024a1.46 1.46 0 0 1 .143-.538.758.758 0 0 1 .254-.302C1.498 3.078 1.675 3 2 3h11.991zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2z" />
                </svg>
                <span className='ms-2'>Serie</span>
            </label>
        </div>
    )
}

const FormRow = (props: { addedClass?: string, children: ReactNode }) => {
    return (
        <div className='row justify-content-md-center'>
            <div className={`col-12 col-lg-8 col-xl-6 mb-3 ${props.addedClass}`}>
                {props.children}
            </div>
        </div>
    );
}

const ProgressBar = (props: { value: number, title: string, error: boolean }) => {
    let status = '';
    if (props.value >= 100) status = 'bg-success';
    if (props.error) status = 'bg-danger'

    return (
        <div>
            <h5 className='h5'>{props.title}</h5>
            <div
                className="progress"
                role="progressbar"
                aria-label="Animated striped example"
                aria-valuenow={props.value}
                aria-valuemin={0}
                aria-valuemax={100}
            >
                <div
                    className={`progress-bar progress-bar-striped progress-bar-animated ${status}`}
                    style={{ width: `${props.value}%` }}
                >
                </div>
            </div>
        </div>
    )
}

export default Uploader;

// const testMovie: ApiMovie = {
//     adult: false,
//     backdrop_path: "/ouB7hwclG7QI3INoYJHaZL4vOaa.jpg",
//     genre_ids: [],
//     id: 315162,
//     original_language: "en",
//     original_title: "Puss in Boots: The Last Wish",
//     overview: "Le Chat Potté découvre que sa passion pour l'aventure et son mépris du danger ont fini par lui coûter cher : il a épuisé huit de ses neuf vies, et en a perdu le compte au passage. Afin de retomber sur ses pattes notre héros velu se lance littéralement dans la quête de sa vie. Il s'embarque dans une aventure épique aux confins de la Forêt Sombre afin de dénicher la mythique Etoile à vœu, seule susceptible de lui rendre ses vies perdues. Mais quand il ne vous en reste qu’une, il faut savoir faire profil bas, se montrer prudent et demander de l’aide. C’est ainsi qu’il se tourne vers son ancienne partenaire et meilleure ennemie de toujours : l’ensorcelante Kitty Pattes De Velours. Le Chat Potté et la belle Kitty vont être aidés dans leur quête, à leur corps bien défendant, par Perro, un corniaud errant et galleux à la langue bien pendue et d'une inaltérable bonne humeur.",
//     popularity: 2638.281,
//     poster_path: "/kO35BwoKHyP1VRulxZJVeEl5dvS.jpg",
//     title: "Le Chat Potté 2 : la dernière quête",
//     belongs_to_collection: null,
//     budget: 0,
//     homepage: '',
//     imdb_id: ''
// }