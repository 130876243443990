import axios from 'axios';

const apiInstance = axios.create({
    baseURL: '/api',
    headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": '/'
    }
});

export default apiInstance;