import { useState, useEffect } from 'react';
import { MovieModel } from '../utils/video';
import Card from '../Component/Card';
import apiInstance from '../utils/api';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchPoster } from '../service/poster.service';

const Home = () => {
  const [movies, setMovies] = useState<MovieModel[]>([]);
  const [hasMoreMovie, setHasMoreMovie] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);

  const fetchMoviePage = async (pageLoad: number) => {
    try {
      const newMovies: MovieModel[] = (await apiInstance.get(`/all?page=${pageLoad}`)).data;

      if (!newMovies.length) {
        setHasMoreMovie(false);
        return [];
      }

      const posterIds = newMovies.map(({ id }) => id);
      const srcPosters = await fetchPoster(posterIds, "movie");
      const movieFetchedPoster = newMovies.map((mv, i) => ({ ...mv, posterPath: srcPosters[i] }));

      return movieFetchedPoster;
    } catch (error: any) {
      setHasMoreMovie(false);
      return [];
    }
  };

  const loadNextMovie = async () => {
    const nextPage = page + 1;
    const movies = await fetchMoviePage(nextPage);
    setMovies(prev => [...prev, ...movies]);
    setPage(nextPage);
  }

  useEffect(() => {
    fetchMoviePage(0).then(movies => {
      setMovies(movies);
    });

    return setMovies([]);
  }, []);

  return (
    <div className="App">
      <div className='container'>

        <InfiniteScroll
          dataLength={movies.length}
          next={loadNextMovie}
          hasMore={hasMoreMovie}
          loader={<h4>Loading...</h4>}
          scrollThreshold={0.99}
          className='row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 row-cols-xl-5 g-3'
          endMessage={
            <h4 className='w-100 text-center'>Fin des propositions pensez à faire circuler les vôtres</h4>
          }
        >

          {movies?.map((movie: MovieModel) =>
            <Card
              key={"card_" + movie.id + (new Date()).toISOString() + (Math.random().toString())}
              id={movie.video.id}
              idPoster={movie.id}
              title={movie.title}
              srcPoster={movie.posterPath}
              type='movie'
              clickPath={`/play/${movie.video.id}`}
            />
          )}

        </InfiniteScroll>

      </div>
    </div>
  );
};

export default Home;