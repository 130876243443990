import { useEffect, useState } from "react";
import InputTypehead from "./inputTypehead"
import { useDebounce } from "use-debounce";

import './search.scss';
import apiInstance from "../utils/api";
import { useNavigate } from "react-router-dom";

const searchIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg>

interface SearchResponse {
    id: number;
    videoId?: number;
    title: string;
}

interface Props {
    onClick? : Function;
}

export const SearchBar = (props: Props) => {
    const [search, setSearch] = useState<string>("");
    const [searchRes, setSearchRes] = useState<SearchResponse[]>([]);
    const [debouncedText] = useDebounce(search, 500);
    const navigate = useNavigate();

    useEffect(() => {
        if (debouncedText) {
            apiInstance.get(`/search?searchText=${debouncedText}`)
              .then(res => {
                setSearchRes(res.data)
            })
              .catch((e) => {
                setSearchRes([]);
              });
          } else {
            setSearchRes([]);
          }
    }, [debouncedText])

    const selectResponse = (value: any) => {
        if(props.onClick)
            props.onClick();
            
        // Movie case
        if(value.videoId)
            return navigate(`/play/${value.videoId}`);

        // Serie case
        return navigate(`/serie/${value.title}`);
    }

    return (
        <InputTypehead
            name="inputSearch"
            onInputChange={setSearch}
            value={search}
            options={searchRes.map(res => {return { label: res.title, value: {title: res.title, videoId: res.videoId} }})}
            onClick={selectResponse}
            icon={searchIcon}
            placeholder="Filme, Serie ..."
        />
    )
}