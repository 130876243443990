import { useEffect, useState } from "react";
import { getAuthHeaderParam } from "../utils/auth";
import apiInstance from "../utils/api";
import { Link, useLocation } from "react-router-dom";
import { VideoInfo } from "../utils/video";

const widthPlayStyle = { maxWidth: "60rem", width: "100%" };
const previousIcon = <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-skip-start-fill" viewBox="0 0 16 16">
  <path d="M4 4a.5.5 0 0 1 1 0v3.248l6.267-3.636c.54-.313 1.232.066 1.232.696v7.384c0 .63-.692 1.01-1.232.697L5 8.753V12a.5.5 0 0 1-1 0V4z" />
</svg>

const nextIcon = <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-skip-end-fill" viewBox="0 0 16 16">
  <path d="M12.5 4a.5.5 0 0 0-1 0v3.248L5.233 3.612C4.693 3.3 4 3.678 4 4.308v7.384c0 .63.692 1.01 1.233.697L11.5 8.753V12a.5.5 0 0 0 1 0V4z" />
</svg>

const Player = () => {
  const location = useLocation();
  const [videoUrl, setVideoUrl] = useState<string>('/api' + location.pathname + `?${getAuthHeaderParam().name}=${getAuthHeaderParam().value}`);
  const [info, setInfo] = useState<VideoInfo>();

  useEffect(() => {
    const videoId = location.pathname.split("/").pop() || "";
    setVideoUrl('/api' + location.pathname + `?${getAuthHeaderParam().name}=${getAuthHeaderParam().value}`);

    // Request video information
    apiInstance.get(`/video/data/${videoId}`)
      .then(res => {
        setInfo(res.data);
        console.log('res.data', res.data)
      })
      .catch(err => {
        // console.log('err', err);
      });
  }, [location]);

  return (
    <div className="d-flex flex-column align-items-center mx-lg-5 mx-3">

      <div style={widthPlayStyle}>
        <h3 className="h3">
          {info?.serie ?
            <>
              <Link className="link-light" to={`/serie/${info?.serie?.title}`}>{info?.serie?.title} {info?.season?.name}</Link>
            </>
            :
            info?.title
          }
        </h3>

        {info?.number &&
          <h4 className="h4">
            {`Episode ${info.title} - `}
            <span className="h4 text-secondary"> Episode {info?.number}</span>
          </h4>
        }
      </div>

      <div className="ratio ratio-16x9 my-3" style={widthPlayStyle}>
        <video key={videoUrl} className="rounded shadow" id="videoPlayer" controls autoPlay playsInline>
          <source src={videoUrl} type="video/mp4" />
        </video>
      </div>

      <div className="d-flex justify-content-center gap-5" style={widthPlayStyle}>
        {info?.previousEpId &&
          <Link className="btn btn-primary " style={{ width: "10rem" }} to={`/play/${info?.previousEpId}`}>{previousIcon} Précédent</Link>
        }
        {info?.nextEpId &&
          <Link className="btn btn-primary " style={{ width: "10rem" }} to={`/play/${info?.nextEpId}`}>Suivant {nextIcon}</Link>
        }
      </div>

      <p style={{ maxWidth: "75rem" }}>{info?.description}</p>

    </div>
  )
};

export default Player;