import { useNavigate } from "react-router-dom";
import { removeToken } from "../utils/auth";

interface Props {
    isLogged: boolean;
    resetUser: () => void;
} 

const Logout = (props: Props) => {
    const navigate = useNavigate();
    
    const handleClick = () => {
        removeToken();
        props.resetUser();
        navigate("/login");
    }

    if(!props.isLogged)
        return <></>;

    return (<div>
        <button
            className="btn btn-dark btn-outline-secondary position-relative bottom-0 start-100 mb-3"
            style={{transform: "translateX(-100%)"}} 
            type="button" onClick={handleClick}
        >
            Déconnexion
        </button>
    </div>);
}
 
export default Logout;