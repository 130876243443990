import './inputTypehead.scss';

type Option<T extends unknown> = {
    value: T;
    label: string;
};

interface Props<T> {
    title?: string;
    name: string;
    value: string;
    options: Option<T>[];
    onInputChange: (e: string) => void;
    onClick: (e: T) => void;
    placeholder?: string;
    icon?: JSX.Element
}

const InputTypehead = <T extends unknown>(props: Props<T>) => {

    const onInputChange = (e: any) => {
        const newValue: string = e.target.value;
        props.onInputChange(newValue);
    }

    const onClick = (option: Option<T>) => {
        props.onClick(option.value);
    }

    const inputStyle = () => {
        if (props.icon)
            return {
                backgroundColor: "var(--bs-tertiary-bg)",
                boxShadow: "none",
                border: "var(--bs-border-width) solid var(--bs-border-color)",
                borderLeft: "none"
            }
    }

    return (
        <div className="form-group typeahead">

            {props.title &&
                <label className="form-label" htmlFor={`inputTypehead_${props.title}`} >{props.title}</label>
            }

            <div className="input-group mb-3">
                {props.icon &&
                    <span
                        className='input-group-text'
                        style={{ borderRight: "none" }}
                    >
                        {props.icon}
                    </span>
                }

                <input
                    type="text"
                    className="form-control typeahead-input"
                    name={props.name}
                    value={props.value}
                    onChange={onInputChange}
                    id={`inputTypehead_${props.title}`}
                    placeholder={props.placeholder}
                    autoComplete="off"
                    style={inputStyle()}
                />

                <div className="typeahead-dropdown list-group" >
                    {props.options.map((opt, i) =>
                        <div
                            key={`key_${i}_${opt}`}
                            // href="#"
                            className="list-group-item"
                            onClick={() => onClick(opt)}
                        >
                            {opt.label}
                        </div>
                    )}
                </div>
            </div>

        </div>
    )
}

export default InputTypehead;