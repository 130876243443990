import { useEffect, useState } from "react";
import { Season } from "../utils/video";
import apiInstance from "../utils/api";
import { Link, redirect, useParams } from "react-router-dom";

export const SeasonPage = () => {
    let { serieTitle } = useParams();
    const [seasons, setSeasons] = useState<Season[]>();

    useEffect(() => {
        getAllSeasons(serieTitle);
    }, [serieTitle])

    const getAllSeasons = (serieTitle?: string) => {
        if (!serieTitle)
            return redirect("/");

        apiInstance.get(`serie/${serieTitle}`)
            .then(res => res.data)
            .then((data: Season[]) => {
                setSeasons(data);
            })
            .catch(err => {
                return redirect("/");
            });
    }

    return (
        <div className='container'>
            <div className="accordion" id="accordionSeason">
                <div className="row justify-content-center">
                    <div className="col-10 col-lg-6">

                        {seasons?.map((season, i) =>
                            <div key={season.id} className="accordion-item">

                                <h2 className="accordion-header">
                                    <button
                                        className={`accordion-button collapsed`}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#${season.id}`}
                                        aria-expanded="false"
                                        aria-controls={`${season.id}`}
                                    >
                                        {season.name ?? `Saison ${season.number}`}
                                    </button>
                                </h2>

                                <div
                                    id={`${season.id}`}
                                    className={`accordion-collapse collapse`}
                                    data-bs-parent="#accordionSeason"
                                >
                                    <div className="list-group list-group-flush list-group-numbered">
                                        {season.episodes.map(ep =>
                                            <Link className="list-group-item list-group-item-action" key={`${season.id}_${ep.id}`} to={`/play/${ep.videoId}`}>
                                                {ep.title}
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
}