import axios from "axios";
import { VideoType } from "../utils/video";
import apiInstance from "../utils/api";

/**
 * Create and return an array of endpoint for the api to get poster.
 * 
 * @param idsPoster movie or series id
 * @param type movie | tv
 * @returns 
 */
const createPosterEndpoints = (idsPoster: number[], type: VideoType) => {
    const path = (type === 'movie')? 'poster' : 'serie/poster';

    return idsPoster.map(id => `/${path}/${id}`);
}

/**
 * Request to get an array of poster src.
 * In error case return default poster.
 * 
 * @param idsPoster movie or series id
 * @param type movie | tv 
 * @returns 
 */
export const fetchPoster = async (idsPoster: number[], type: VideoType) => {
    
    const endpoints = createPosterEndpoints(idsPoster, type);

    try {
        const res = await axios.all(endpoints.map(endpoint => apiInstance.get(endpoint, { responseType: "blob" })));
        return res.map(({ data }) => URL.createObjectURL(data));
    } catch (err) {
        // console.log('err', err);

        return Array<string>(idsPoster.length).fill("../assets/images/default_movie.png");
    }
}