import React, { useState } from 'react';
import './Signup.css';
import apiInstance from '../utils/api';

const Signup: React.FC= () => {
    const [resMessage, setResMessage] = useState<string | undefined>(undefined);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const target = event.currentTarget;

        const newUser = {
            pseudo: target.pseudo.value,
            password: target.password.value
        };

        apiInstance.post('/auth/signup', newUser)
            .then(async response => {
                if (response.status === 201) {
                    setResMessage('Bienvenu utilisateur ajouté');
                }
            }).catch(err => {
                setResMessage(err.response.data);
            });
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="formContainer">
                <h1>Inscription</h1>

                {resMessage && <p className="errorSignup">{resMessage}</p>}

                <label htmlFor="pseudoField">Pseudo</label>
                <input type="text" name="pseudo" id="pseudoField" required />

                <label htmlFor="passwordField">Mot de passe</label>
                <input type="password" name="password" id="passwordField" required />

                <input type="submit" value="S'inscrire" />
            </form>
        </div>
    );
};

export default Signup;