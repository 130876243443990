import { useEffect, useState } from "react";
import { SerieModel } from "../utils/video";
import Card from "../Component/Card";
import apiInstance from "../utils/api";
import { fetchPoster } from "../service/poster.service";
import InfiniteScroll from "react-infinite-scroll-component";

const Serie = () => {
    const [series, setSeries] = useState<SerieModel[]>([]);
    const [hasMoreSerie, setHasMoreSerie] = useState<boolean>(true);
    const [page, setPage] = useState<number>(0);

    const fetchSeriePage = async (pageLoad: number) => {
        try {
            const newSeries: SerieModel[] = (await apiInstance.get(`/serie/all?page=${pageLoad}`)).data;

            if (!newSeries.length) {
                setHasMoreSerie(false);
                return [];
            }

            const posterIds = newSeries.map(({ id }) => id);
            const srcPosters = await fetchPoster(posterIds, "tv");
            const serieFetchedPoster = newSeries.map((mv, i) => ({ ...mv, poster: srcPosters[i] }));

            return serieFetchedPoster;
        } catch (error: any) {
            // console.log("error", error.response);
            return [];
        }
    };

    const loadNextSerie = async () => {
        const nextPage = page + 1;
        const series = await fetchSeriePage(nextPage);
        setSeries(prev => [...prev, ...series]);
        setPage(nextPage);
    }

    useEffect(() => {
        fetchSeriePage(0).then(series => {
            setSeries(series);
        });

        return setSeries([]);
    }, []);

    return (
        <div className='container'>
            <InfiniteScroll
                dataLength={series.length}
                next={loadNextSerie}
                hasMore={hasMoreSerie}
                loader={<h4>Loading...</h4>}
                scrollThreshold={0.99}
                className='row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 row-cols-xl-5 g-3'
                endMessage={<h4>Fin des propositions pensez à faire circuler les vôtres</h4>}
            >
                {series?.map((serie: SerieModel) =>
                    <Card
                        key={"card_serie" + serie.id}
                        id={serie.id}
                        idPoster={serie.id}
                        title={serie.title}
                        srcPoster={serie.poster}
                        type="tv"
                        clickPath={`/serie/${serie.title}`}
                    />
                )}
            </InfiniteScroll>

        </div>
    );
}

export default Serie;